import { useMemo, useEffect } from 'react';
import styles from './Municipality.module.scss';

const Municipality = ({
  foundationData,
  activeMunicipality,
  setActiveMunicipality,
}) => {
  const municipalities = useMemo(() => {
    if (!foundationData) return [];

    if (foundationData.length > 1) {
      const municipalitiesFiltered = foundationData
        .map((foundation) =>
          foundation.years
            .flatMap((item) => item.handouts)
            .map((handout) => handout.organisation?.municipality?.name)
        )
        .flat();
      const municipalities = municipalitiesFiltered;

      // Return only unique values
      return [...new Set(municipalities)];
    } else {
      const municipalities = foundationData[0].years
        ?.flatMap((item) => item.handouts)
        ?.map((handout) => handout.organisation?.municipality?.name);
      // Return only unique values
      return [...new Set(municipalities)];
    }
  }, [foundationData]);

  return (
    <div className={styles.container}>
      <p className={styles.label}>Kommune</p>

      {municipalities.length > 1 ? (
        <>
          <button
            onClick={() => setActiveMunicipality('alle')}
            className={activeMunicipality === 'alle' ? styles.active : null}
          >
            Alle
          </button>
          {municipalities?.map((municipality, index) => (
            <button
              onClick={() => setActiveMunicipality(municipality.toLowerCase())}
              className={
                activeMunicipality === municipality.toLowerCase()
                  ? styles.active
                  : null
              }
              key={index}
            >
              {municipality}
            </button>
          ))}
        </>
      ) : (
        <>
          <button className={styles.active}>Alle</button>
        </>
      )}
    </div>
  );
};

export default Municipality;
