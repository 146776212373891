import { useState, useEffect } from 'react';
import { sanityClient } from './sanityClient';

export const useFoundationData = (foundationId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFoundationData();
  }, []);

  function fetchFoundationData() {
    const foundationQuery = foundationId
      ? `_id == '${foundationId}'`
      : `_id != '1b79bdc1-1ed0-4787-99df-13cd73053f78'`;
    const query = `*[_type == 'foundation' && ${foundationQuery}]{
        _id, name,
        years[]{
          _key, year,
          handouts[]{
            _key, amount, purpose,
            organisation->{
              _id, description, name, location,
              municipality->{
                _id, name,
              },
              images[]{
                _key, alttext, "url": image.asset->url
              }
            }
          }
        }
      }`;

    setIsLoading(true);

    sanityClient
      .fetch(query)
      .then(setData)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }

  return { isLoading, data, error };
};
