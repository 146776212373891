import { useMemo } from 'react';
import styles from './Years.module.scss';

const Years = ({ foundationData, activeYear, setActiveYear }) => {
  const years = useMemo(() => {
    if (!foundationData) return [];

    let foundationDataFlat;
    if (foundationData.length > 1) {
      const foundationDataFiltered = foundationData
        .map((foundation) => foundation.years.map((item) => item.year))
        .flat();
      foundationDataFlat = foundationDataFiltered;
    }

    // Extract all years from foundationData
    const yearsFiltered = foundationDataFlat
      ? foundationDataFlat
      : foundationData[0].years?.map((item) => item.year);
    const years = [...new Set(yearsFiltered)];

    return years?.sort((a, b) => b - a);
  }, [foundationData]);

  function handleChange(e) {
    setActiveYear(e.target.value);
  }

  return (
    <div className={styles.container}>
      <p className={styles.label}>År for tildeling</p>
      <div>
        <select onChange={handleChange} value={activeYear}>
          <option value="alle">Vis alle</option>
          {years?.map((year, index) => (
            <option key={`${year}-${index}`} value={year}>
              {year}
            </option>
          ))}
        </select>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.85459 6.85506C6.80814 6.90162 6.75297 6.93857 6.69222 6.96377C6.63148 6.98898 6.56636 7.00195 6.50059 7.00195C6.43482 7.00195 6.3697 6.98898 6.30896 6.96377C6.24821 6.93857 6.19304 6.90162 6.14659 6.85506L0.146589 0.85506C0.0527029 0.761173 -4.19617e-05 0.633835 -4.19617e-05 0.50106C-4.19617e-05 0.368284 0.0527029 0.240946 0.146589 0.147059C0.240476 0.0531726 0.367814 0.0004282 0.500589 0.0004282C0.633365 0.0004282 0.760703 0.0531726 0.854589 0.147059L6.50059 5.79406L12.1466 0.147059C12.2405 0.0531726 12.3678 0.0004282 12.5006 0.0004282C12.6334 0.0004282 12.7607 0.0531726 12.8546 0.147059C12.9485 0.240946 13.0012 0.368284 13.0012 0.50106C13.0012 0.633835 12.9485 0.761173 12.8546 0.85506L6.85459 6.85506Z"
            fill="#023047"
          />
        </svg>
      </div>
    </div>
  );
};

export default Years;
