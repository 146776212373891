import { useMemo, useState, useEffect } from 'react';
import 'npm-overlapping-marker-spiderfier';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
  InfoWindow,
} from '@react-google-maps/api';
import InformationModal from './informationModal/InformationModal';
import styles from './Map.module.scss';

const center = {
  lat: 58.7,
  lng: 5.68,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapId: 'b1a98efb86e17e35',
};

const clusterStyles = [
  {
    height: 50,
    textColor: '#ffffff',
    width: 50,
    url: "data:image/svg+xml,%3Csvg width='45' height='43' viewBox='0 0 45 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='22.5' cy='21.5' rx='22.5' ry='21.5' fill='%23E74060' fill-opacity='0.29'/%3E%3Ccircle cx='22.5' cy='21.5' r='18.5' fill='%23E74060' fill-opacity='0.62'/%3E%3Ccircle cx='22.5' cy='21.5' r='14.5' fill='%23E74060'/%3E%3C/svg%3E",
  },
];

const markerIcon = {
  url: "data:image/svg+xml,%3Csvg width='27' height='34' viewBox='0 0 27 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5 0C6.05651 0 0.000137436 6.1013 0.000137436 13.5915C-0.0487996 24.548 12.987 33.6328 13.5 34C13.5 34 27.0488 24.548 26.9999 13.6C26.9999 6.1013 20.9435 0 13.5 0ZM13.5 20.4C9.77066 20.4 6.75007 17.357 6.75007 13.6C6.75007 9.843 9.77066 6.8 13.5 6.8C17.2293 6.8 20.2499 9.843 20.2499 13.6C20.2499 17.357 17.2293 20.4 13.5 20.4Z' fill='%23E74060'/%3E%3C/svg%3E%0A",
};

const Map = ({
  foundationData,
  handoutsFiltered,
  selectedHandoutKey,
  setSelectedHandoutKey,
  mapKey,
  hideAmount,
}) => {
  const [oms, setOms] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [infoWindowPos, setInfoWindowPos] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    language: 'no',
    googleMapsApiKey: 'AIzaSyD2hVbBw1L75gPcm3Nbhhl7tIH1V50FNK0',
  });

  const onLoad = (map) => {
    let oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
    var newOms = new oms.OverlappingMarkerSpiderfier(map, {
      markersWontMove: true, // we promise not to move any markers, allowing optimizations
      markersWontHide: true, // we promise not to change visibility of any markers, allowing optimizations
      basicFormatEvents: true, // allow the library to skip calculating advanced formatting information
    });
    setOms(newOms);
  };

  // Get organisations from foundationData and add a full list of handouts to each organisation
  const organisations = useMemo(() => {
    if (!foundationData) return [];

    if (foundationData.length > 1) {
      // Extract all handouts from foundationData and add year to each handout
      const allHandoutsFiltered = foundationData
        .map((foundation) =>
          foundation.years.flatMap((item) =>
            item.handouts.map((handout) => ({ ...handout, year: item.year }))
          )
        )
        .flat();

      const allHandouts = allHandoutsFiltered;

      const organisations = allHandouts?.reduce((accumulator, current) => {
        if (!current.organisation) return accumulator;

        const handoutFormatted = {
          _key: current._key,
          amount: current.amount,
          purpose: current.purpose,
          year: current.year,
        };

        const existingOrganisation = accumulator.find(
          (item) => item._id === current.organisation._id
        );

        if (existingOrganisation) {
          existingOrganisation.handouts.push(handoutFormatted);
        } else {
          accumulator.push({
            ...current.organisation,
            handouts: [handoutFormatted],
          });
        }

        return accumulator;
      }, []);

      return organisations;
    } else {
      // Extract all handouts from foundationData and add year to each handout
      const allHandouts = foundationData[0].years?.flatMap((item) =>
        item.handouts.map((handout) => ({ ...handout, year: item.year }))
      );

      const organisations = allHandouts?.reduce((accumulator, current) => {
        if (!current.organisation) return accumulator;

        const handoutFormatted = {
          _key: current._key,
          amount: current.amount,
          purpose: current.purpose,
          year: current.year,
        };

        const existingOrganisation = accumulator.find(
          (item) => item._id === current.organisation._id
        );

        if (existingOrganisation) {
          existingOrganisation.handouts.push(handoutFormatted);
        } else {
          accumulator.push({
            ...current.organisation,
            handouts: [handoutFormatted],
          });
        }

        return accumulator;
      }, []);

      return organisations;
    }
  }, [foundationData]);

  useEffect(() => {
    if (!selectedHandoutKey) setSelectedOrganisation(null);
    // Get selected organisation based on selected handout key
    const organisation = organisations?.find((item) =>
      item.handouts?.some((handout) => handout._key === selectedHandoutKey)
    );
    setSelectedOrganisation(organisation);
  }, [selectedHandoutKey, organisations]);

  useEffect(() => {
    if (!handoutsFiltered?.length) setMarkers([]);

    /* const locations = organisations.reduce((acc = [], currentOrg) => {
      // Sjekk om det finnes en gruppe med samme lokasjon fra før
      const existingLocationGroup = acc.find(
        (group) =>
          group.lat === currentOrg.location.lat &&
          group.lng === currentOrg.location.lng
      );

      if (existingLocationGroup) {
        // Legg org til gruppen hvis den finnes
        existingLocationGroup.orgs.push(currentOrg);
        return [...acc];
      } else {
        // Hvis ikke lag en ny gruppe
        const groupForLocation = {
          lat: currentOrg.location.lat,
          lng: currentOrg.location.lng,
          orgs: [currentOrg],
        };
        return [...acc, groupForLocation];
      }
    }, []);

    const adjustedLocations = locations.filter((item) => item.orgs.length > 1);
    adjustedLocations.forEach((elem) => {
      elem.orgs.forEach((item, index) => {
        const index0 = index + 1;
        item.location.lat =
          item.location.lat +
          Math.sin(((2 * 3.14159265359) / elem.orgs.length) * index0) * 0.00001;
        item.location.lng =
          item.location.lng +
          Math.sin(((2 * 3.14159265359) / elem.orgs.length) * index0) * 0.00001;
      });
    }); */

    // Get markers from handoutsFiltered and add lat, lng and handoutKey
    setMarkers(
      handoutsFiltered
        // Filter out organisations without location
        .filter(
          (item) =>
            item.organisation?.location?.lat && item.organisation?.location?.lng
        )
        // Filter out duplicate organisations
        .reduce((accumulator = [], currentElement) => {
          const includesOrganisation = accumulator.some(
            (item) =>
              item.organisation?.name === currentElement.organisation?.name
          );
          if (includesOrganisation) {
            return accumulator;
          } else {
            return [...accumulator, currentElement];
          }
        }, [])
        // Add properties, offset location
        ?.map((item) => ({
          lat: item.organisation.location.lat /*  +
            ((Math.random() > 0.5 ? 0.000001 : -0.000009) +
              Math.random() * 0.000008) */,
          lng: item.organisation.location.lng /*  +
            ((Math.random() > 0.5 ? 0.000001 : -0.000009) +
              Math.random() * 0.000008) */,
          handoutKey: item._key,
        }))
    );
  }, [handoutsFiltered]);

  useEffect(() => {
    if (!selectedHandoutKey) return;

    // Get selected marker based on selected handout key
    const marker = markers?.find(
      (item) => item.handoutKey === selectedHandoutKey
    );

    if (marker) setInfoWindowPos({ lat: marker.lat, lng: marker.lng });
  }, [selectedHandoutKey, markers]);

  function handleMarkerClick(event, handoutKey) {
    setSelectedHandoutKey(handoutKey);
  }

  function handleInfoWindowClose() {
    setSelectedHandoutKey(null);
    setInfoWindowPos(null);
  }

  return !isLoaded ? null : (
    <div className={styles.container}>
      <GoogleMap
        onLoad={onLoad}
        language="no"
        mapContainerClassName={styles.map}
        center={center}
        zoom={11}
        options={options}
        key={mapKey}
      >
        {markers?.length > 0 && (
          <MarkerClusterer
            options={{
              styles: clusterStyles,
              maxZoom: 19,
            }}
          >
            {(clusterer) =>
              markers.map(({ lat, lng, handoutKey }, index) => (
                <Marker
                  icon={markerIcon}
                  key={`marker-${handoutKey}-${index}`}
                  position={{ lat, lng }}
                  clusterer={clusterer}
                  onLoad={(marker) => {
                    oms.addMarker(marker);
                    window.google.maps.event.addListener(
                      marker,
                      'spider_click',
                      (e) => {
                        handleMarkerClick(e, handoutKey);
                      }
                    );
                  }}
                ></Marker>
              ))
            }
          </MarkerClusterer>
        )}
        {selectedOrganisation && infoWindowPos ? (
          <InfoWindow
            position={infoWindowPos}
            onCloseClick={handleInfoWindowClose}
            shouldFocus={true}
          >
            <InformationModal organisation={selectedOrganisation} hideAmount={hideAmount} />
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
};

export default Map;
