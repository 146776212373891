import { useMemo } from 'react';
import styles from './Foundation.module.scss';

const Foundation = ({
  foundationData,
  activeFoundation,
  setActiveFoundation,
}) => {
  const foundations = useMemo(() => {
    if (!foundationData) return [];

    // Extract all foundations from foundationData
    const foundations = foundationData?.flatMap((item) => ({
      _id: item?._id,
      name: item.name,
    }));

    // Return only unique values
    return [...new Set(foundations)];
  }, [foundationData]);

  return (
    <div className={styles.container}>
      <p className={styles.label}>Sparebankstiftelse</p>

      {foundations.length > 1 ? (
        <>
          <button
            onClick={() => setActiveFoundation('alle')}
            className={activeFoundation === 'alle' ? styles.active : null}
          >
            Alle
          </button>
          {foundations?.map((foundation, index) => (
            <button
              onClick={() => setActiveFoundation(foundation._id)}
              className={
                activeFoundation === foundation._id ? styles.active : null
              }
              key={index}
            >
              {foundation.name.replace('Sparebankstiftelsen Jæren - ', '')}
            </button>
          ))}
        </>
      ) : (
        <>
          <button className={styles.active}>Alle</button>
        </>
      )}
    </div>
  );
};

export default Foundation;
