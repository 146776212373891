import { useState, useEffect } from 'react';
import styles from './Table.module.scss';

const Table = ({ handouts, selectedHandoutKey, setSelectedHandoutKey, hideAmount, isLoading }) => {
  const totalAmount = handouts?.reduce((acc, cur) => acc + cur.amount, 0);
  const formatNumber = new Intl.NumberFormat('no', {
    currency: 'nok',
  });
  const [handoutsSorted, setHandoutsSorted] = useState(handouts);

  // Sort table alphabetically
  useEffect(() => {
    const sortHandouts = [...handouts];
    sortHandouts.sort((a, b) =>
      a.organisation.name < b.organisation.name ? -1 : 1
    );
    setHandoutsSorted(sortHandouts);
  }, [handouts]);

  function handleRowClick(handoutKey) {
    setSelectedHandoutKey(handoutKey);

    if (window.innerWidth < 1000) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>Navn på organisasjon</th>
            <th>Formålet</th>
            {!hideAmount ? <th>Beløp i kr</th> : null}
          </tr>
          {/* <tr aria-hidden="true" className={styles.background}></tr> */}
        </thead>
        <tbody>
          {handoutsSorted.length > 0 ? (
            handoutsSorted?.map((handout, i) => (
              <tr
                key={i}
                onClick={() => handleRowClick(handout._key)}
                className={
                  selectedHandoutKey === handout._key
                    ? styles.activeRow + ' activeRow'
                    : ''
                }
              >
                <td>{handout.organisation.name}</td>
                <td>{handout.purpose}</td>
                {!hideAmount ? (
                  <td>
                    {formatNumber.format(handout.amount)}
                    <button>
                      Mer informasjon om {handout.organisation.name}
                    </button>
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr className={styles.nobackground} key="no-results">
              <td>{isLoading ? 'Laster inn...' : 'Ingen resultater'}</td>
              {!hideAmount ? <td></td> : null}
              <td className={styles.noresults}></td>
            </tr>
          )}
        </tbody>
        {!hideAmount ? (
          <tfoot className={styles.sum}>
            <tr>
              <td>Sum</td>
              <td></td>
              <td>{formatNumber.format(totalAmount)}</td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    </div>
  );
};

export default Table;
